import React from "react";

import { FaHeart, FaBars } from "react-icons/fa";
//import reactLogo from "./assets/logo.svg"

import Homepage from "../../../container/HomePage/HomePage";

import About from "../../../container/About/About";
import axios from "axios";
import urldata from "../../../urldata";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// components
import JobApplyModel from "../../../component/JobApplyModel/JobApplyModel";
import JobApplication from "../Views/career/JobApplication";
import Jobpostlist from "../Views/career/Jobpostlist";
import ContactUsFormList from "../Views/contact_us/ContactUsFormList";
import CollaborateFormList from "../Views/collaborate/CollaborateFormList";
import NewsLetter from "../Views/NewsLetter";
import CvUpload from "../Views/career/CvUpload";
import HomepageBannerList from "../Views/homepage/HomepageBannerList";
import HomepageAboutSec1 from "../Views/homepage/HomepageAboutSec1";
import AddOurPartners from "../Views/our_brands/AddOurPartners";
import PressRelease from "../Views/media/PressRelease";
import TvAds from "../Views/media/TvAds";
import AboutUsBanner from "../Views/about_us/AboutUsBanner";
import OurBrandsBanner from "../Views/our_brands/OurBrandsBanner";
import MediaBanner from "../Views/media/MediaBanner";
import CollaborateBanner from "../Views/collaborate/CollaborateBanner";
import CareerBanner from "../Views/career/CareerBanner";
import CareerSubBanner from "../Views/career/CareerSubBanner";
import ContactBanner from "../Views/contact_us/ContactBanner";
import OurJourney from "../Views/homepage/OurJourney";

import OurPresence from "../Views/contact_us/OurPresence";
import GetInTouch from "../Views/contact_us/GetInTouch";

import OurPartnerBrands from "../Views/our_brands/OurPartnerBrands";
import CollaborateSecTwo from "../Views/collaborate/CollaborateSecTwo";
import CollaborateSecThree from "../Views/collaborate/CollaborateSecThree";
import Brands from "../Views/our_brands/Brands";
import AboutUsSecTwo from "../Views/about_us/AboutUsSecTwo";
import AboutUsSecThree from "../Views/about_us/AboutUsSecThree";
import AboutUsSecFour from "../Views/about_us/AboutUsSecFour";
// import CareerSecTwo from "../Views/career/CareerSecTwo";
import OurProductBrands from "../Views/our_brands/OurProductBrands";
import OurBrandDesc from "../Views/homepage/OurBrandDesc";
import OurPartnerBrandDesc from "../Views/homepage/OurPartnerBrandDesc";
import InHouseBrandDesc from "../Views/our_brands/InHouseBrandDesc";
import OurPartBrandDesc from "../Views/our_brands/OurPartBrandDesc";
import FooterContent from "../Views/footer/FooterContent";
import PrivacyPolicyBanner from "../Views/privacy_policy/PrivacyPolicyBanner";
import PolicyDetails from "../Views/privacy_policy/PolicyDetails";
import AddPdf from "../Views/pdfpage/AddPdf";
import FooterSocialMedia from "../Views/footer/FooterSocialMedia";
import Product from "../Views/Product/Index";
import ProductCategory from "../Views/ProductCategory/Index";
import ProductBrand from "../Views/ProductBrand/Index";
import ProductContent from "../Views/ProductContent/Index";
import Role from "../Views/Role/Index";
import User from "../Views/User/Index";
import Welcome from "../Views/Welcome/Index";

import CheckPermission from "../../../Middlewares/CheckPermission";
import HomepageSlider from "../Views/HomepageSlider/Index";
import CareerTestimonial from "../Views/CareerTestimonial/Index";
import CareerGallery from "../Views/CareerGallery/Index";
import CareerDepartment from "../Views/CareerDepartment/Index";
import CareerCountry from "../Views/CareerCountry/Index";
import CareerLocation from "../Views/CareerLocation/Index";
import CareerExperience from "../Views/CareerExperience/Index";
import CareerJob from "../Views/CareerJob/Index";
import CareerJobLead from "../Views/Leads/CareerJobLead";
import CareerInterviewLead from "../Views/Leads/CareerInterviewLead";

// >>>>>>> 1af93315e62524c67afc136e836b9eac17cefe62

const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  history,
}) => {
  const logoutBtnClick = (id) => {
    let url = new URL(urldata + "admin/logout");

    axios({
      method: "post",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        localStorage.clear();
        history.push("/admin");
      })
      .catch((error) => {
        localStorage.clear();
        history.push("/admin");
      });
  };
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <header className="d-f jc-sb a-c">
        <p>Dashboard</p>
        <div
          className="logout_btn c-p"
          onClick={() => {
            logoutBtnClick();
          }}
        >
          Logout
        </div>
      </header>

      <>
        <div className="dashboardDataContainer">
          <Switch>
            {/* homepage routes */}
            <CheckPermission
              exact
              module="about_us"
              path="/admin/dashboard/aboutussectwo"
              component={AboutUsSecTwo}
              
            />
            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/ourbranddesc"
              component={OurBrandDesc}
            />
            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/ourpartnerbranddesc"
              component={OurPartnerBrandDesc}
            />
            <CheckPermission
              exact
              module="about_us"
              path="/admin/dashboard/aboutussecthree"
              component={AboutUsSecThree}
            />
            <CheckPermission
              exact
              module="about_us"
              path="/admin/dashboard/aboutussecfour"
              component={AboutUsSecFour}
            />
            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/ourjourney"
              component={OurJourney}
            />
            {/*end of homepage CheckPermissions */}
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/jobapplication"
              component={JobApplication}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/cvupload"
              component={CvUpload}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/Jobpostlist"
              component={Jobpostlist}
            />
            <CheckPermission
              exact
              module="contact_us"
              path="/admin/dashboard/contactForm"
              component={ContactUsFormList}
            />
            <CheckPermission
              exact
              module="collaborate"
              path="/admin/dashboard/collaborateForm"
              component={CollaborateFormList}
            />

            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/hompeagebannerlist"
              component={HomepageBannerList}
            />
            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/homepage/sliders"
              component={HomepageSlider}
            />

            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/HomepageAboutSec1"
              component={HomepageAboutSec1}
            />
            {/* add our partners component*/}
            <CheckPermission
              exact
              module="our_brands"
              path="/admin/dashboard/addourpartners"
              component={AddOurPartners}
            />

            {/* tv ads */}
            <CheckPermission exact
            module="media" path="/admin/dashboard/tvads" component={TvAds} />
            {/* press release */}
            <CheckPermission
              exact
              module="media"
              path="/admin/dashboard/pressrelease"
              component={PressRelease}
            />

            {/* <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/newsletter"
              component={NewsLetter}
            /> */}

            {/********** all banners CheckPermission throught website*/}
            <CheckPermission
              exact
              module="about_us"
              path="/admin/dashboard/aboutusbanner"
              component={AboutUsBanner}
            />

            <CheckPermission
              exact
              module="our_brands"
              path="/admin/dashboard/ourbrandsbanner"
              component={OurBrandsBanner}
            />

            <CheckPermission
              exact
              module="our_brands"
              path="/admin/dashboard/ourpartnerbrands"
              component={OurPartnerBrands}
            />
            {/* <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/brands/:id"
              component={Brands}
            /> */}

            <CheckPermission
              exact
              module="media"
              path="/admin/dashboard/mediabanner"
              component={MediaBanner}
            />

            {/* collaborate section */}
            <CheckPermission
              exact
              module="collaborate"
              path="/admin/dashboard/collaboratebanner"
              component={CollaborateBanner}
            />
            <CheckPermission
              exact
              module="collaborate"
              path="/admin/dashboard/collaboratesectwo"
              component={CollaborateSecTwo}
            />
            <CheckPermission
              exact
              module="collaborate"
              path="/admin/dashboard/collaboratesecthree"
              component={CollaborateSecThree}
            />

            {/* end of collaborate section */}
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careerbanner"
              component={CareerBanner}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careerSubbanner"
              component={CareerSubBanner}
            />
            <CheckPermission
              exact
              module="contact_us"
              path="/admin/dashboard/contactbanner"
              component={ContactBanner}
            />
            {/* end of all banners CheckPermission throught website************/}

            {/* <<<<<<< HEAD */}
            {/* contact CheckPermissions */}

            <CheckPermission
              exact
              module="contact_us"
              path="/admin/dashboard/ourpresence"
              component={OurPresence}
            />
            <CheckPermission
              exact
              module="contact_us"
              path="/admin/dashboard/getintouch"
              component={GetInTouch}
            />
            {/* end of contact CheckPermissions */}

            <CheckPermission
              exact
              module="homepage"
              path="/admin/dashboard/ourproductbrands/:id"
              component={OurProductBrands}
            />
            <CheckPermission
              exact
              module="our_brands"
              path="/admin/dashboard/inhousebrandsdesc"
              component={InHouseBrandDesc}
            />
            <CheckPermission
              exact
              module="our_brands"
              path="/admin/dashboard/ourpartbrandsintrotext"
              component={OurPartBrandDesc}
            />
            <CheckPermission
              exact
              module="footer"
              path="/admin/dashboard/footerdetails"
              component={FooterContent}
            />
            <CheckPermission
              exact
              module="privacy_policy"
              path="/admin/dashboard/privacypolicy"
              component={PrivacyPolicyBanner}
            />
            <CheckPermission
              exact
              module="privacy_policy"
              path="/admin/dashboard/policydetails"
              component={PolicyDetails}
            />
            <CheckPermission
              exact
              module="pdf_content"
              path="/admin/dashboard/pdfdetails"
              component={AddPdf}
            />
            <CheckPermission
              exact
              module="footer"
              path="/admin/dashboard/footersocialmedia"
              component={FooterSocialMedia}
            />
            <CheckPermission exact
            module="our_catalogue" path="/admin/dashboard/products" component={Product} />
            <CheckPermission
              exact
              module="our_catalogue"
              path="/admin/dashboard/products/categories"
              component={ProductCategory}
            />
            <CheckPermission
              exact
              module="our_catalogue"
              path="/admin/dashboard/products/brands"
              component={ProductBrand}
            />
            <CheckPermission
              exact
              module="our_catalogue"
              path="/admin/dashboard/products/content"
              component={ProductContent}
            />
            <CheckPermission
              exact
              module="user_manage"
              path="/admin/dashboard/roles"
              component={Role}
            />
            <CheckPermission
              exact
              module="user_manage"
              path="/admin/dashboard/users"
              component={User}
            />
            <Route
              exact
             
              path="/admin/dashboard/welcome"
              component={Welcome}
            />


            {/* career v2 routes */}
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/testimonials"
              component={CareerTestimonial}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/galleries"
              component={CareerGallery}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/departments"
              component={CareerDepartment}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/countries"
              component={CareerCountry}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/locations"
              component={CareerLocation}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/experiences"
              component={CareerExperience}
            />
             <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/careers/jobs"
              component={CareerJob}
            /> 
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/leads/career-job-leads"
              component={CareerJobLead}
            />
            <CheckPermission
              exact
              module="career"
              path="/admin/dashboard/leads/career-interview-leads"
              component={CareerInterviewLead}
            />

            <Redirect to="/admin/dashboard/welcome" />
          </Switch>
        </div>
      </>

      <footer></footer>
    </main>
  );
};

export default Main;
