import React from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaTachometerAlt,
  FaGem,
  FaList,
  FaGithub,
  FaRegLaughWink,
  FaHeart,
  AiOutlineContacts,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
//import sidebarBg from './assets/bg1.jpg';

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  user,
}) => {
  console.log({ user });

  return (
    <ProSidebar
      // image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      style={{zIndex:0}}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Hos Admin Dashboard
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          {user?.role?.permissions?.includes("homepage") && (
            <SubMenu title="Homepage" icon={<FaGem />}>
              {/* <MenuItem>
                <NavLink
                  to="/admin/dashboard/hompeagebannerlist"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Banner
                </NavLink>
              </MenuItem> */}
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/homepage/sliders"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Sliders
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/HomepageAboutSec1"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  About us section
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourjourney"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Journey
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourbranddesc"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Brand Introduction
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourpartnerbranddesc"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Partner Brand Introduction
                </NavLink>
              </MenuItem>

              {/* <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem> */}
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("about_us") && (
            <SubMenu title="About Us" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/aboutusbanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  About us Banner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/aboutussectwo"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  About us Section Two
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/aboutussecthree"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  About us Section Three
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/aboutussecfour"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  About us Section Four
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("our_brands") && (
            <SubMenu title="Our Brands" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourbrandsbanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Banner
                </NavLink>
              </MenuItem>
              {/* add our partners */}
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/addourpartners"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our partners Brands
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourpartnerbrands"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Brands
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/inhousebrandsdesc"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  In House Brand Introduction
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourpartbrandsintrotext"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Partner Brand Introduction
                </NavLink>
              </MenuItem>

              {/* <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem> */}
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("media") && (
            <SubMenu title="Media" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/mediabanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Media Banner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/tvads"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Tv Ads
                </NavLink>
              </MenuItem>
              {/* add our partners */}
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/pressrelease"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Press Release
                </NavLink>
              </MenuItem>

              {/* <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem> */}
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("collaborate") && (
            <SubMenu title="Collaborate" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/collaboratebanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Collaborate Banner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/collaboratesectwo"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Collaborate Section two
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/collaboratesecthree"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Collaborate Section Three
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/collaborateForm"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Collaborate Form
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}

          {/* {user?.role?.permissions?.includes("career") && (
            <SubMenu title="Career" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careerbanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Career Banner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careerSubbanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Career Section Two
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/jobapplication"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Job Applications
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/cvupload"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Drop Your CV List
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/Jobpostlist"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Job Post List
                </NavLink>
              </MenuItem>
            
            </SubMenu>
          )} */}

          {user?.role?.permissions?.includes("career") && (
            <SubMenu title="Career" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/countries"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Countries
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/locations"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Locations
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/departments"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Departments
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/experiences"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Experiences
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/jobs"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Jobs
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/leads/career-job-leads"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Job Entries
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/leads/career-interview-leads"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Interview Entries
                </NavLink>
              </MenuItem>
             {/* <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/testimonials"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Testimonials
                </NavLink>
                </MenuItem> */}
              {/* <MenuItem>
                <NavLink
                  to="/admin/dashboard/careers/galleries"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Galleries
                </NavLink>
              </MenuItem> */}
            </SubMenu>
          )}
          {user?.role?.permissions?.includes("contact_us") && (
            <SubMenu title="Contact Us" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/contactbanner"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Contact Banner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/contactForm"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Contact Us Form
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/ourpresence"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Our Presence
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/getintouch"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Get In Touch
                </NavLink>
              </MenuItem>
              {/* <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem> */}
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("footer") && (
            <SubMenu title="Footer" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/footerdetails"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Footer
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/footersocialmedia"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Footer Social Media
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}
          {user?.role?.permissions?.includes("privacy_policy") && (
            <SubMenu title="Privacy Policy" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/privacypolicy"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Banner
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/policydetails"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Policy Details
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("pdf_content") && (
            <SubMenu title="PDF Content" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/pdfdetails"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Add PDF
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}

          {user?.role?.permissions?.includes("our_catalogue") && (
            <SubMenu title="Our Catalogue" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/products/brands"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Brands
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/products/categories"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Categories
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/admin/dashboard/products">Products</NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to="/admin/dashboard/products/content"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Content
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}
          {user?.role?.permissions?.includes("user_manage") && (
            <SubMenu title="User Management" icon={<FaGem />}>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/roles"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Roles
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/admin/dashboard/users"
                  activeStyle={{
                    color: "white",
                  }}
                >
                  Users
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}

          {/* <MenuItem icon={<FaGem />}>
            <NavLink
              to="/admin/dashboard/newsletter"
              activeStyle={{
                color: "white",
              }}
            >
              News Letter List
            </NavLink>
          </MenuItem> */}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        ></div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
