import React, { useState, useEffect, useRef, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.less";
import uniqid from "uniqid";
import locationIcon from "../../images/footer/location.png";
import fb from "../../images/footer/fb.png";
import inst from "../../images/footer/inst.png";
import linkdin from "../../images/footer/linkdin.png";
import mail from "../../images/footer/mail.png";
import call from "../../images/contactus/callwhite.svg";
import sendIcon from "../../images/footer/sendIcon.png";
import viewProduct from "../../images/footer/viewProduct.png";
import youtube from "../../images/footer/youtube.png";
import useWindowDimensions from "../../useWindowDimensions";
import axios from "axios";
import urldata from "../../urldata";
import Toast from "../../component/Toast/Toast";
import close from "../../images/close.svg";
import check from "../../images/check.svg";
import moment from "moment";
import Imageurldata from "../../Imageurldata";
import useOnScreen from "../../hooks/useOnScreen";
import { CatalogueContext } from "../../contexts/CatalogueContext";
import { useLocation } from "react-router-dom";

const Footer = (props) => {
  // const { setIsFooterInViewPort } = useContext(CatalogueContext);
  const { height, width } = useWindowDimensions();
  const footerRef = useRef();
  const isVisible = useOnScreen(footerRef);
  const [emailId, setEmailId] = useState("");
  const [emailIderr, setEmailIderr] = useState("");
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [list, setList] = useState([]);
  const [footerDetails, setFooterDetails] = useState("");
  let toastProperties = null;
  const location = useLocation();
  const currentSlug = location.pathname.split("/").pop(); // Get the current slug from the URL

  // Define your condition here
  const slugClassMap = ["careers-v2", "careers-details"];

  // Check if the currentSlug exists in slugClassMap
  const hasMatch = slugClassMap.includes(currentSlug);
  // useEffect(() => {
  //   setIsFooterInViewPort(isVisible);
  // }, [isVisible]);

  // console.log(footerDetails)
  const signUpBtnClick = () => {
    var formIsValid = true;

    if (typeof emailId !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(emailId)) {
        formIsValid = false;
        setEmailIderr("*Please enter valid email-Id.");
      } else {
        setEmailIderr("");
      }
    }

    if (formIsValid) {
      //console.log(props.id)

      let data = {};

      data.emailId = emailId;
      data.date = moment().format("DD-MM-YYYY");
      data.time = moment().format("hh:mm a");

      // alert(data.time)
      let url = new URL(urldata + "user/createNewsLetter");

      axios({
        method: "post",
        url: url,
        data: data,
        withCredentials: true,
      })
        .then((response) => {
          setEmailId("");
          showToast("success");
        })
        .catch((error) => {
          showToast("danger");
        });
    }
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Your Email Id Saved Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;

      default:
        setList([]);
    }

    setList([...list, toastProperties]);
  };

  useEffect(() => {
    let url = new URL(urldata + "user/getFooterData");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response)
        setFooterDetails(response.data.data.footerdata[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let url = new URL(urldata + "admin/getsocial");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response)
        setSocialMediaData(response.data.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const socialMediaArr = socialMediaData.map((socialMediaItem) => (
    <a
      // href="https://www.facebook.com/LaxmiFoodsBrand"
      key={uniqid()}
      href={`${socialMediaItem.sociallink}`}
      target="_blank"
    >
      <img
        className="img-fluid pleft pleft-social c-p"
        src={Imageurldata + socialMediaItem.imgUrl}
      />
    </a>
  ));

  return (
    <div
      className={`footerContainer ${hasMatch ? "sticky" : ""}`}
      id="footerId"
      ref={footerRef}
    >
      <Row className="footerRowContainer m-0">
        <Col lg={4} className="d-f f-c">
          <div className="contactUsTxt">{footerDetails.contacttitle}</div>
          <div className="d-f mtop">
            <div>
              <img src={locationIcon} className="location img-fluid" />
            </div>
            <div className="addtxt pleft">
              {footerDetails.address ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: footerDetails.address.replace(
                      /(<? *script)/gi,
                      "illegalscript"
                    ),
                  }}
                ></div>
              ) : (
                ""
              )}
              {/* House of Spices – 3000 Marcus Avenue, Suite 2W10, Lake Success, NY
              11042 */}
            </div>
          </div>
          <div className="contactUsTxt mtop">{footerDetails.customertitle}</div>
          <div className="d-f mtop">
            <div>
              <img src={call} className="location img-fluid" />
            </div>

            <div className="addtxt pleft">
              <a href={`tel:${footerDetails.contact}`}>
                {footerDetails.contact}
              </a>
            </div>
          </div>
        </Col>
        <Col lg={4} className="d-f f-c seccol">
          <div className="contactUsTxt">{footerDetails.visittitle}</div>
          <div className="mtop">
            <a href={`${footerDetails.amazonimglink}`} target="_blank">
              <img
                src={Imageurldata + footerDetails.amazonimg}
                className="viewProduct c-p img-fluid"
              />
            </a>
          </div>

          <div className="contactUsTxt followusContainer ">
            {footerDetails.followtitle}
          </div>
          <div className="mtop followusContainerList">{socialMediaArr}</div>
        </Col>
        <Col lg={4} className="d-f f-c seccol">
          {/* <div className="contactUsTxt">Customer Service number</div>
          <div className="d-f mtop">
          <div>
              <img src={call} className="location img-fluid" />
            </div>
            
            <div className="addtxt pleft">
            <a href="tel:71805074600">718-507-4600</a>
            </div>
          </div> */}
          <div className="contactUsTxt">{footerDetails.onlinetitle}</div>
          <div className="d-f mtop">
            <div>
              <img src={mail} className="mail  img-fluid" />
            </div>
            <div className="addtxt pleft mw-f">
              <a href={`mailto: ${footerDetails.email}`}>
                {footerDetails.email}
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <div className="copyrightContainer">
        {width > 480 ? (
          <div className="copyrightTxt">
            <span
              className="c-p"
              onClick={() => props.history.push("/privacy-policy")}
            >
              Privacy Policy
            </span>{" "}
            &nbsp;
            {/* | <a href="#">Terms & Conditions</a>{" "} */}|&nbsp;
            {footerDetails.copyrightText}
            &nbsp;|&nbsp; Crafted by{" "}
            <a href="https://www.togglehead.in/" target="_blank">
              Togglehead
            </a>
          </div>
        ) : (
          <div className="copyrightTxt">
            <span
              className="c-p"
              onClick={() => props.history.push("/privacy-policy")}
            >
              Privacy Policy
            </span>{" "}
            &nbsp;
            {/* | <a href="#">Terms & Conditions</a>{" "} */}
            <br></br> {footerDetails.copyrightText} | Crafted by{" "}
            <a href="https://www.togglehead.in/" target="_blank">
              Togglehead
            </a>
          </div>
        )}
      </div>
      <Toast toastList={list} position="top-right" />
    </div>
  );
};

export default Footer;
// <div className="footerContainer">
//   <Row className="footerRowContainer m-0">
//     <Col lg={4} className="d-f f-c">
//       <div className="contactUsTxt">{footerDetails.contacttitle ? footerDetails.contacttitle: ''}</div>
//       <div className="d-f mtop">
//         <div>
//           <img src={location} className="location img-fluid" />
//         </div>
//         <div className="addtxt pleft">
//           {footerDetails.address ? (
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: footerDetails.address.replace(
//                   /(<? *script)/gi,
//                   "illegalscript"
//                 ),
//               }}
//             ></div>
//           ) : (
//             ""
//           )}

//         </div>
//       </div>
//       <div className="contactUsTxt mtop">{footerDetails.customertitle? footerDetails.customertitle: ''}</div>
//       <div className="d-f mtop">
//         <div>
//           <img src={call} className="location img-fluid" />
//         </div>

//         <div className="addtxt pleft">
//           <a href={`tel:${footerDetails.contact}`}>
//             {footerDetails.contact ? footerDetails.contact: ''}
//           </a>
//         </div>
//       </div>
//     </Col>
//     <Col lg={4} className="d-f f-c seccol">
//       <div className="contactUsTxt">{footerDetails.visittitle ? footerDetails.visittitle: ''}</div>
//       <div className="mtop">
//         <a href={`${footerDetails.amazonimglink}`} target="_blank">
//           <img
//             src={Imageurldata + footerDetails.amazonimg}
//             className="viewProduct c-p img-fluid"
//           />
//         </a>
//       </div>

//       <div className="contactUsTxt followusContainer ">
//         {footerDetails.followtitle ? footerDetails.followtitle: ''}
//       </div>
//       <div className="mtop followusContainerList">
//         {socialMediaArr}
//       </div>
//     </Col>
//     <Col lg={4} className="d-f f-c seccol">

//       <div className="contactUsTxt">{footerDetails.onlinetitle ? footerDetails.onlinetitle: ''}</div>
//       <div className="d-f mtop">
//         <div>
//           <img src={mail} className="mail  img-fluid" />
//         </div>
//         <div className="addtxt pleft mw-f">
//           <a href={`mailto: ${footerDetails.email}`}>
//             {footerDetails.email ? footerDetails.email: ''}
//           </a>
//         </div>
//       </div>
//     </Col>
//   </Row>
//   <div className="copyrightContainer">
//     {width > 480 ? (
//       <div className="copyrightTxt">
//         <span
//           className="c-p"
//           onClick={() => props.history.push("/privacy-policy")}
//         >
//           Privacy Policy
//         </span>{" "}
//         &nbsp;
//        |&nbsp; Copyright
//         2021 House Of Spices (India) Inc. &nbsp;|&nbsp; Crafted by{" "}
//         <a href="https://www.togglehead.in/" target="_blank">
//           Togglehead
//         </a>
//       </div>
//     ) : (
//       <div className="copyrightTxt">
//         <span
//           className="c-p"
//           onClick={() => props.history.push("/privacy-policy")}
//         >
//           Privacy Policy
//         </span>{" "}
//         &nbsp;

//         <br></br> Copyright 2021 House Of Spices (India) Inc. | Crafted by{" "}
//         <a href="https://www.togglehead.in/" target="_blank">
//           Togglehead
//         </a>
//       </div>
//     )}
//   </div>
//   <Toast toastList={list} position="top-right" />
// </div>

// <a
//   // href="https://www.facebook.com/LaxmiFoodsBrand"
//   href={`${footerDetails.fbimglink}`}
//   target="_blank"
// >
//   <img
//     className="img-fluid c-p"
//     src={Imageurldata + footerDetails.fbimg}
//   />
// </a>
// <a
//   // href="https://www.instagram.com/laxmifoodsbrand/"
//   href={`${footerDetails.instaimglink}`}
//   target="_blank"
// >
//   <img
//     className="img-fluid pleft c-p"
//     src={Imageurldata + footerDetails.instaimg}
//   />
// </a>
// <a
//   // href="https://www.linkedin.com/company/houseofspices"
//   href={`${footerDetails.linkedinimglink}`}
//   target="_blank"
// >
//   <img
//     className="img-fluid pleft c-p"
//     src={Imageurldata + footerDetails.linkedinimg}
//   />
// </a>
// <a
//   // href=" https://www.youtube.com/channel/UCPfZSIvMvERGxThwsm_e3Ug"
//   href={`${footerDetails.youtubeimglink}`}
//   target="_blank"
// >
//   <img
//     className="img-fluid pleft c-p"
//     src={Imageurldata + footerDetails.youtubeimg}
//   />
// </a>
