import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./container/HomePage/HomePage";
import HomepageV2 from "./container/HomePage/HomePageV2";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";
import About from "./container/About/About";

import OurBrands from "./container/OurBrands/OurBrands";
import Collaborate from "./container/Collaborate/Collaborate";

import Contactus from "./container/ContactUS/Contactus";
// import Careers from "./container/Careers/Careers";
import PageNotFound from "./container/PageNotFound/PageNotFound";
import InternalServerError from "./container/InternalServerError/InternalServerError";
import Media from "./container/Media/Media";
import PrivacyPolicy from "./container/PrivacyPolicy/PrivacyPolicy";
import Maintainence from "./container/Maintainence/Maintainence";
import Catalogue from "./container/Catalogue/Catalogue";
import CareersV2 from "./container/CareersV2/Careers-v2";
import CareersDetail from "./container/CareersDetail/CareersDetail";
import { careerListingURL, careersV2URL, careersV3URL,careersV4URL, careersV5URL } from "./helpers/paths";
import CareersV3 from "./container/CareersV2/Careers-v3";
import CareersV4 from "./container/CareersV2/Careers-v4";
import CareersV5 from "./container/CareersV2/Careers-v5";

const DefaultLayout = (props) => {
  return (
    <>
      <Header />
      <div className="marginMiddleHomeContainer">
        <Switch>
          {/* <Route exact path="/" component={InternalServerError} /> */}
          <Route exact path="/" component={Homepage} />
          <Route exact path="/home-v2" component={HomepageV2} />
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/about" component={Maintainence} /> */}
          <Route exact path="/contactus" component={Contactus} />

          <Route exact path="/our-brands" component={OurBrands} />
          <Route exact path="/catalogue" component={Catalogue} />

          <Route exact path="/collaborate" component={Collaborate} />
          {/* <Route exact path="/collaborate" component={Maintainence} /> */}

          {/* <Route exact path="/careers" component={Careers} /> */}
        
          <Route exact path={careersV2URL} component={CareersV5} />
          {/* <Route exact path={careersV5URL} component={CareersV5} /> */}
          <Route exact path={careerListingURL} component={CareersDetail} />
          <Route exact path="/careers-details/:jobId" component={CareersDetail} />
          <Route exact path="/404" component={PageNotFound} />
          <Route exact path="/500" component={InternalServerError} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/media" component={Media} />
          <Route exact path="/media/:id" component={Media} />
          <Redirect to="/404"></Redirect>
        </Switch>
      </div>
      <Footer history={props.history} />
    </>
  );
};

export default DefaultLayout;
